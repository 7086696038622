#editor .fixed-header {
    width: 100vw;
    background: white;
    position: fixed;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    z-index: 1000;
    padding: 2rem 2rem 1rem 2rem;
}

#editor .site-page-header {
    border: 1px solid rgb(235, 237, 240);
}

#editor main {
    padding: 8rem 2rem;
    background-color: white;
}

#editor .ant-form-item-control-input-content img {
    width: 100%
}

#editor .upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-right: 8px;
  }
  
#editor  .upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
float: right;
}