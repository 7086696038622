:root {
  --gallery-media-border-radius: 4px;
}

#gallery {
  background-color: #f0f0f0;
  color: black;
  padding: 1rem;
  padding-top: 7rem;
  height: auto;
}

#gallery .image-gallery {
  width: calc(100vw - 2rem);
}

#gallery .header .ant-btn {
  position: relative;
  left: -0.2rem;
  margin-top: 0.5rem;
}

#gallery .ant-btn {
  border: 0;
  border-radius: 0.75rem;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: baseline;
  flex-shrink: 0;
}

#gallery .ant-btn-sm {
  padding: 0.125rem 0.5rem;
  height: auto;
  border-radius: 0.8rem;
}

#gallery .ant-btn.ant-btn-sm .button-text {
  margin-left: 0.25rem;
  margin-right: 0;
  padding-right: 0;
  border: 0;
}

#gallery .ant-btn.ant-btn-sm .button-icon {
  display: grid;
  align-items: center;
  box-sizing: border-box;
  padding-left: 0.1rem;
  padding-top: 0.05rem;
}

#gallery .ant-btn span {
  align-self: center;
}

#gallery .image-gallery .memory {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  position: relative;
}

#gallery .image-gallery .memory .media {
  border-radius: var(--gallery-media-border-radius);
  overflow: hidden;
}

#gallery .image-gallery .memory .media img,
#gallery .image-gallery .memory .media video,
#gallery .image-gallery .memory .media canvas {
  border-radius: var(--gallery-media-border-radius);
  width: 100%;
  object-fit: contain;
}

#gallery .image-gallery .memory .caption {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 1rem;
  margin-top: 0.6rem;
}

#gallery .image-gallery .memory .text {
  max-width: 26rem;
  text-align: left;
}

#gallery .image-gallery .memory .panorama {
  cursor: grab;
  position: relative;
  width: 100%;
  aspect-ratio: 3/2;
}

#gallery .image-gallery .memory .panorama canvas:active {
  cursor: grabbing;
}

#gallery .image-gallery .memory .panorama .panoramaIcon {
  position: absolute;
  bottom: 0rem;
  right: 1rem;
  pointer-events: none;
}

/* panorama menu */

#gallery .contribute-button {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 10000;
  display: block;
}

/* Responsive Adjustments */

@media only screen and (min-width: 576px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 992px) {
  #gallery {
    padding: 6rem 3rem;
  }
  #gallery .image-gallery {
    width: calc(100vw - 6rem);
  }
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1400px) {
}
