#viewer body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  background: #f0f0f0;
}

#viewer *,
#viewer .ui {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

#viewer #sound-toggle-button {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 10000;
}

#viewer .canvas {
  height: 100vh !important;
  width: 100vw !important;
  top: 0rem !important;
  left: 0rem !important;
  position: fixed !important;
}

#viewer #scene {
  cursor: grab;
}

#viewer #scene:active {
  cursor: grabbing;
}

#viewer #scene {
  height: calc(100vh - 0rem);
  width: calc(100vw - 0rem);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  cursor: grab;
}

#viewer #scene .hotspot {
  background: rgba(255, 255, 255, 0);
  border: 5px solid rgba(255, 255, 255, 1);
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  transform: scale(1);
  /* animation: pulse 12s infinite; */
  cursor: pointer;
  transition: 0.25s;
}

#viewer #scene .hotspot:hover {
  background: rgba(255, 255, 255, 1);
  transition: 0.25s;
}

#viewer #scene .hotspot.visited {
  border: 6px double rgba(255, 255, 255, 1);
  height: 3.5rem;
  width: 3.5rem;
}

/*
#viewer #scene .locationLabel {
  position: relative;
  background-color: white;
  padding: 0.5rem 0.85rem;
  border-radius: 0.75rem;
  text-align: center;
  left: -0.07rem;
  width: fit-content;
  max-width: 70vw;
  margin: 0 auto 0.65rem auto;
  display: flex;
  align-items: baseline;
}
*/

#viewer #scene .locationLabel {
  position: relative;
  margin: 0 auto 0.65rem auto;
  overflow: visible;
}

#viewer #scene .locationLabel:after {
  content: "";
  position: absolute;
  display: block;
  top: 95%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-top: solid 0.5rem #ffffff;
  border-left: solid 0.5rem transparent;
  border-right: solid 0.5rem transparent;
}

/*
#viewer #scene .locationLabel .verb {
  margin-right: 0.5rem;
}

#viewer #scene .locationLabel .location {
  width: 100%;
}
*/
@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  40% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  50% {
    transform: scale(1);
    box-shadow: 0 0 0 1rem rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

#viewer #photoGallery {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
  width: 100vw;
  box-sizing: border-box;
}

#viewer #photoGallery .memoryWrapper {
  margin: auto;
  width: fit-content;
  text-align: center;
  margin-bottom: 5vh;
}

#viewer #photoGallery .memoryWrapper.panorama .panoViewer {
  cursor: grab;
}

#viewer #photoGallery .memoryWrapper.panorama .panoViewer canvas:active {
  cursor: grabbing;
}

#viewer #photoGallery .memoryWrapper.panorama .panoramaIcon {
  position: absolute;
  bottom: 0rem;
  right: 1rem;
  pointer-events: none;
}

#viewer #photoGallery img,
#viewer #photoGallery video {
  margin: auto;
  pointer-events: auto;
  border-radius: 4px;
}

#viewer #photoGallery .memoryWrapper .caption {
  position: absolute;
  left: 6px;
  bottom: 6px;
  background-color: white;
  color: black;
  border-radius: 2px;
  transition: opacity 0.25s;
  text-align: left;
  max-width: calc(100% - 12px);
  padding: 0.5rem 1rem 0.75rem 1rem;
}

#viewer #photoGallery .memoryWrapper .caption .caption-text {
  max-width: 100%;
}

#viewer #photoGallery .memoryWrapper .caption.hidden {
  opacity: 0;
}

#viewer #photoGallery .memoryWrapper .caption.visible {
  opacity: 1;
}

#viewer #photoGallery .gallery-spacer-top {
  width: 100vw;
  height: 90vh;
}

#viewer #photoGallery .gallery-spacer-bottom {
  z-index: 1000;
  width: 100vw;
  pointer-events: all;
  pointer-events: all;
  margin-bottom: -15vh;
}

#viewer #photoGallery .gallery-images {
  width: 100vw;
  display: flex;
  flex-direction: column;
  pointer-events: all;
  margin-bottom: -15vh;
}
#viewer #photoGallery .gallery-images .gallery-image-background {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100%;
  box-sizing: content-box;
}

#viewer .gallery-buttons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  position: fixed;
  bottom: 1rem;
  width: 100vw;
  padding: 0 1rem;
  pointer-events: none;
}

@media only screen and (min-width: 768px) {
  #viewer #photoGallery .memoryWrapper {
    margin-bottom: 5vw;
  }

  #viewer #photoGallery .gallery-spacer-top {
    height: 100vh;
  }

  #viewer #photoGallery .memoryWrapper .caption {
    padding: 1rem 1.5rem;
    max-width: 26rem;
  }

  #viewer .gallery-buttons {
    display: flex;
    justify-content: center;
  }
}
