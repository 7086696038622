.ant-dropdown .ant-menu {
  font-family: Inter, sans-serif;
  position: fixed;
  border: 0;
  border-radius: 0.75rem;
  overflow: hidden;
  font-weight: 500;
  max-height: 75vh;
  width: 24rem;
  width: fit-content;
  max-width: calc(100vw - 2rem);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.ant-dropdown .ant-menu .ant-menu-item-group-title,
.ant-dropdown .ant-menu .ant-menu-item {
  font-size: 1rem;
}

.ant-dropdown .ant-menu .ant-menu-item-group-title {
  font-weight: 600;
  margin: 0;
  padding: 1rem 1rem 0.5rem;
  border-top: 1px solid #eee;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.ant-dropdown .ant-menu .ant-menu-item {
  height: 2rem;
  padding: 0 0.75rem;
  display: flex;
  align-items: center;
  border-radius: 0.6rem;
  margin: 0 0.25rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-dropdown .ant-menu .ant-menu-item:hover {
  color: rgba(0, 0, 0, 0.86);
  background: rgba(0, 0, 0, 0.05);
}

.memory-description {
  color: black;
}

#active-selection .category {
  margin: 0;
  padding: 0 0.5rem 0 0;
}

.ant-dropdown
  .ant-menu
  .ant-menu-item-group:first-child
  .ant-menu-item-group-title {
  border-top: 0;
}

.ant-dropdown .ant-menu .ant-menu-item-selected {
  border-radius: 0.5rem;
  background: rgba(0, 0, 0, 0.05);
}

.ant-dropdown .ant-menu-item-group-list {
  padding-bottom: 1rem;
}

.ant-dropdown .ant-menu .ant-menu-item .memory-count {
  margin-left: 0.35rem;
}

#panorama-menu {
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 10000;
}

#panorama-menu #active-selection {
  max-width: calc(100vw - 9.5rem);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
