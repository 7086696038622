body,
html {
  background-color: #f0f0f0;
  font-size: 16px;
  width: 100vw;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
}

.caption {
  font-family: "Source Serif 4", serif;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.3;
  max-width: 100%;
  text-align: left;
}

.ui-text {
  font-family: Inter, sans-serif;
  white-space: nowrap;
  font-weight: 500;
}

.ui-text .meta {
  font-family: Inter, sans-serif;
  font-weight: 600;
  letter-spacing: 0.02em;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.45);
}
.ui-text .primary {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.ant-btn:focus,
.ant-btn:hover {
  color: black;
  transform: scale(1.02);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.ant-btn {
  border: 0;
  border-radius: 0.75rem;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
}

@supports (font-variation-settings: normal) {
  .ui-text {
    font-family: InterVariable, sans-serif;
    font-variation-settings: "wght" 475;
  }
  .ui-text .meta {
    font-family: InterVariable, sans-serif;
    font-variation-settings: "wght" 550;
  }
  .caption {
    text-rendering: optimizeLegibility;
    font-family: "Source Serif 4Variable", serif;
    font-variation-settings: "wght" 475, "opsz" 35;
    font-size: 1.3rem;
    line-height: 1.25;
  }
}

.button-text {
  display: flex;
  align-items: baseline;
  margin-right: 0.6rem;
  padding-right: 0.65rem;
  border-right: 1.5px solid #eee;
}

.ant-btn.locationLabel {
  padding: 0.125rem 0.5rem;
  height: auto;
  border-radius: 0.8rem;
}

.ant-btn.locationLabel .button-text {
  display: flex;
  align-items: baseline;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  padding-right: 0;
  border: 0;
}

.ant-btn.locationLabel .button-text .meta {
  position: relative;
  top: 0.0155rem;
  margin-right: 0.25rem;
  font-size: 0.875;
  font-size: 10.5px;
}

/*
.ant-btn.locationLabel .button-text {
  margin: 0;
  border: 0;
  padding: 0;
}
*/

.button-icon {
  position: relative;
  display: flex;
  align-items: center;
}

.button-text.collapse {
  display: none;
  margin: 0;
  padding: 0;
  border: 0;
}

@media only screen and (min-width: 768px) {
  .button-text.collapse {
    display: block;
    margin-right: 0.6rem;
    padding-right: 0.65rem;
    border-right: 1.5px solid #eee;
  }
}

.loader,
.loader:after {
  border-radius: 50%;
  height: 4.25rem;
  width: 4.25rem;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 5px solid rgba(255, 255, 255, 0.2);
  border-right: 5px solid rgba(255, 255, 255, 0.2);
  border-bottom: 5px solid rgba(255, 255, 255, 0.2);
  border-left: 5px solid #ffffff;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(0deg);
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(360deg);
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(0deg);
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(360deg);
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
